import { ITranslation } from "./types";

export const ja: ITranslation = {
  messageFallbacks: {
    homeOrderNotReadyMsg:
      'Just left the store? Your receipt is being processed, please check back in a while.<br/>In case of any issues, reach out for support at <a style="white-space: nowrap;" href="mailto:support@aifi.io">support@aifi.io</a>',
  },
  api: {
    E_FORCED_LOGOUT: "接続がタイムアウトしてログアウトされました。",
  },
  orderStatus: {
    draft: 'Draft',
    contested: 'Contested',
    paid: 'Paid',
    unpaid: 'Unpaid',
    pending: 'Pending',
    reviewed: 'Reviewed'
  },
  receiptsList: {
    headings: {
      date: "Date",
      time: "Time",
      status: "Status",
      total: "Total",
    },
    action: {
      view: "View",
    },
    statusTooltip: {
      draft: "Draft - Order being processed by the system",
      paid: "Paid - Order has been processed and paid",
      paymentProcessing:
        "Payment Processing - Order has been processed and payment is under process",
      unpaid: "Unpaid - Order has been processed but payment failed",
      pending:
        "Pending - Order has been contested by shopper and is pending for review by the retailer",
      reviewed:
        "Reviewed - Order has been contested by shopper and the retailer has submitted the revision",
    },
  },
  receiptDetailScreen: {
    headerTitle: "支払い明細",
    subtotal: "小計",
    taxBreakdown: {
      headers: {
        rate: "Tax %",
        netto: "Netto",
        brutto: "Brutto",
        tax: "Tax",
        total: "Total:",
      },
    },
    tax: "税",
    taxCRV: "CRV",
    total: "計",
    processTime: "処理時刻",
    date: "日付",
    paymentStatus: "支払い状況",
    orderId: "注文ID",
    listOfProducts: "製品",
  },
  receiptDetailComplain: {
    reportProblem: "問題の報告",
    submitReview: "レビューの送信",
    confirmTitle: "送信されたレシート",
    confirmButton: "完了",
    addMissingItem: "不足している項目の追加",
    addMissingItemMessage: "メッセージの追加（任意）",
  },
  receiptsScreen: {
    label: {
      contested: "レビュー中",
      draft: "ドラフト",
      empty: "空欄",
      paid: "支払い済",
      pending: "ドラフト",
      refunded: "返金済",
      reviewed: "修正済",
      unknown: "不明",
      unpaid: "未払い",
    },
    message: {
      contested: "Contested message",
      draft: "Draft message",
      empty: "Empty message",
      paid: "Paid message",
      pending: "Pending message",
      refunded: "Refunded message",
      reviewed: "Reviewed message",
      unpaid: "Unpaid message",
    },
    minutes: "分",
    hours: "時間",
    days: "日々",
  },
  slideModal: {
    close: "閉じる",
    searchPlaceholder: "アイテムの検索",
  },
  receiptsHome: {
    receiptPortal: "レシートポータル",
    selectPaymentType: "領収書を見つけるための希望する方法を選択してください",
    last4: "Last four digits of your payment method",
    last4Apple: "Last four digits of your Apple Pay Number",
    last4Google: "Last four digits of your Google Pay Number",
    instructionApple:
      "Locate last four digits of your Apple Pay Card Number in your Apple Wallet",
    instructionGoogle:
      "Locate last four digits of your Google Pay Card Number in your Wallet",
    instructionExternalId:
      '支払いアプリまたは銀行取引明細書で支払い参照番号を見つけてください',
    externalIdLabel: '支払い参照番号',
    externalIdPlaceholder: '',
    expDate: "Expiration date",
    visitDate: "Date of your visit",
    submit: "Retrieve Receipt(s)",
    registerEmail: "Enter your email to subscribe to future receipts",
    registerEmailSuccess: "Email address updated successfully.",
    registerEmailFail:
      "Email address wasn't updated because there wasn't any receipts associated with provided credit card information and/or visit date.",
  },
};
