import ReceiptDetailSection from "../../../../components/v2/ReceiptsDetailSection";
import { formatCurrency } from "../../../../lib/utils";
import { useReceiptOrder } from "../../ReceiptContext";
import { MasterContext } from '../../../../lib/masterContext'
import { useContext } from "react";

export const CardDataBlock = () => {
  const order = useReceiptOrder()
  const {
    featureFlags: { hideCardBrand },
  } = useContext(MasterContext);

  if (hideCardBrand) {
    return null
  }

  const cardBrand = order.paymentInfo?.paymentInstrument.cardBrand;
  const cardData = cardBrand && [
    {
      title: cardBrand,
      value: formatCurrency(order.totalPrice, order.currencyCode),
      valueTall: true,
    },
  ];

  return cardData ? <ReceiptDetailSection data={cardData} /> : null;
};
