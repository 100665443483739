import * as R from "ramda";
import { TaxBreakdownTable } from "../TaxBreakdownTable";
import { buildTaxBreakdownTable } from "../utils";
import { useReceiptOrder } from "../../ReceiptContext";

export const ProductTaxBreakdownBlock = () => {
  const order = useReceiptOrder()

  const taxBreakdownItems = R.values(order.taxBreakdownTableData ?? {});
  const taxBreakdownTable = buildTaxBreakdownTable(order, taxBreakdownItems);

  return <TaxBreakdownTable data={taxBreakdownTable} />;
};
