import { useContext } from "react";
import { MasterContext } from "src/lib/masterContext";
import ReceiptDetailSection, {
  DetailItem,
} from "../../../../components/v2/ReceiptsDetailSection";
import { translate } from "../../../../lib/i18n";
import { formatCurrency, formatTaxName } from "../../../../lib/utils";
import { useReceiptOrder } from "../../ReceiptContext";

export const ProductChargeDataBlock = () => {
  return (
    <>
      <SubtotalBlock />
      <TaxCRVBlock />
      <TaxBlock />
      <TotalBlock />
    </>
  );
};

export const SubtotalBlock = () => {
  const order = useReceiptOrder();
  const {
    featureFlags: { productsTaxEnabled },
  } = useContext(MasterContext);

  if (!productsTaxEnabled || !order.subtotalPrice) {
    return null;
  }

  return (
    <ReceiptDetailSection
      data={[
        {
          title: translate("receiptDetailScreen.subtotal") as string,
          value: formatCurrency(order.subtotalPrice, order.currencyCode),
        },
      ]}
    />
  );
};

export const TaxCRVBlock = () => {
  const order = useReceiptOrder();
  const {
    featureFlags: { productsTaxEnabled },
  } = useContext(MasterContext);

  if (!productsTaxEnabled || !order.totalCRVTax) {
    return null;
  }

  return (
    <ReceiptDetailSection
      data={[
        {
          title: translate("receiptDetailScreen.taxCRV") as string,
          value: formatCurrency(order.totalCRVTax, order.currencyCode),
        },
      ]}
    />
  );
};

export const TaxBlock = () => {
  const order = useReceiptOrder();
  const {
    featureFlags: { productsTaxEnabled, hideTaxBreakdown },
  } = useContext(MasterContext);

  if (!productsTaxEnabled) {
    return null;
  }

  const chargeData: DetailItem[] = [];
  chargeData.push({
    title: translate("receiptDetailScreen.tax") as string,
    value: formatCurrency(order.totalTax, order.currencyCode),
  });

  if (!hideTaxBreakdown && order.taxBreakdown) {
    for (const item of order.taxBreakdown) {
      chargeData.push({
        title: formatTaxName(item.name, item.rate, 0),
        value: formatCurrency(item.amount, order.currencyCode),
        leftViewStyle: { paddingLeft: "1em" },
      });
    }
  }

  return <ReceiptDetailSection data={chargeData} />;
};

export const TotalBlock = () => {
  const order = useReceiptOrder();
  return (
    <ReceiptDetailSection
      data={[
        {
          title: translate("receiptDetailScreen.total") as string,
          value: formatCurrency(order.totalPrice, order.currencyCode),
          valueTall: true,
        },
      ]}
    />
  );
};
