import { useFormik } from "formik";
import React, { PropsWithChildren, useContext, useMemo, useRef } from "react";
import InputMask from "react-input-mask";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { translate } from "src/lib/i18n";
import styled from "styled-components";
import { ReceiptFormValues } from "./types";

import { buildReceiptSearchValidationSchema } from "./utils";
import { RequestStatus } from "src/lib/api/types";
import { config } from "src/lib/config";
import { Icon } from "src/assets/icons/icon";
import { MasterContext } from "src/lib/masterContext";
import Card from "src/assets/icons/Card";
import BankStatementIcon from "src/assets/icons/BankStatamentIcon";
import { ApplePayInstruction, GooglePayInstructions } from "../Instructions";
import { DashLine } from "src/components/v2/Dashline";
import {count} from "ramda";
import { SearchType } from "src/lib/common/searchTypes";

export const ErrorMessage = styled.div`
  color: #f02849;
  text-align: left;
`;

export const SuccessMessage = styled.div`
  color: #43c9ba;
  text-align: left;
`;

export const WarningMessage = styled.div`
  color: #e9741b;
  text-align: left;
`;

const RequiredLabel = styled(Label)`
  &::after {
    content: "*";
    color: red;
    display: inline;
  }
`;

interface ReceiptsFormProps {
  emailStatus: RequestStatus;
  onSubmit: (values: ReceiptFormValues) => void;
}

export const ReceiptsForm: React.FC<PropsWithChildren<ReceiptsFormProps>> = ({
  emailStatus,
  onSubmit,
  children,
}) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { flavor, appConfig } = useContext(MasterContext);

  const validationSchema = useMemo(()=>{
    return buildReceiptSearchValidationSchema(appConfig.searchByCreditCardRequiresExpiration)
  }, [appConfig.searchByCreditCardRequiresExpiration])

  const receiptsForm = useFormik({
    validateOnMount: true,
    initialValues: {
      customerPaymentInstrumentLast4: "",
      customerPaymentInstrumentExpDate: "",
      externalId: '',
      visitDate: "",
      captchaToken: "",
      email: "",
    },
    validationSchema,
    onSubmit: (args: ReceiptFormValues) => {
      onSubmit(args);
      recaptchaRef?.current?.reset();
    },
  });

  const getLast4Message = (searchType: SearchType | undefined): string => {
    switch (searchType) {
      case "apple":
        return translate("receiptsHome.last4Apple");
      case "google":
        return translate("receiptsHome.last4Google");
      case "card":
      default:
        return translate("receiptsHome.last4");
    }
  };

  const isSearchMethodEnabled: Record<SearchType, boolean> = {
    card: true,
    apple: true,
    google: true,
    externalId: appConfig.allowSearchingByExternalId,
  }

  const numberOfSearchMethods = count((isEnabled) => isEnabled, Object.values(isSearchMethodEnabled))
  const searchButtonWidth = `${(100 / numberOfSearchMethods).toFixed(2)}%`

  return (
    <div style={{ fontWeight: 500 }}>
      <Row className="justify-content-center">
        <Col sm={7} style={{ textAlign: "center" }}>
          <h2 className="mb-3">
            <b>{translate("receiptsHome.receiptPortal")}</b>
          </h2>
          {!receiptsForm.values.searchType && (
            <>
              <DashLine />
              <h5 className="my-3">
                {translate("receiptsHome.selectPaymentType")}
              </h5>
            </>
          )}
        </Col>
      </Row>
      <Form
        className="row justify-content-center"
        onSubmit={receiptsForm.handleSubmit}
      >
        <FormGroup row className="justify-content-center">
          <Col sm={7}>
            <ButtonGroup style={{ width: "100%", maxHeight: "68px" }}>
              {isSearchMethodEnabled.card && (
                <Button
                  style={{ width: searchButtonWidth, display: "flex" }}
                  outline={receiptsForm.values.searchType !== "card"}
                  onClick={() => {
                    receiptsForm.setFieldValue("searchType", "card");
                  }}
                >
                  <Card
                    color={
                      receiptsForm.values.searchType === "card"
                        ? "white"
                        : flavor.getMainColor()
                    }
                  />
                </Button>
              )}
              {isSearchMethodEnabled.apple && (
                <Button
                  style={{ width: searchButtonWidth }}
                  outline={receiptsForm.values.searchType !== "apple"}
                  onClick={() => {
                    receiptsForm.setFieldValue("searchType", "apple");
                  }}
                >
                  <Icon
                    icon="ApplePayIcon"
                    style={{
                      width: "100%",
                      height: "100%",
                      filter:
                        receiptsForm.values.searchType === "apple"
                          ? "invert()"
                          : "",
                    }}
                  />
                </Button>
              )}
              {isSearchMethodEnabled.google && (
                <Button
                  style={{ width: searchButtonWidth }}
                  outline={receiptsForm.values.searchType !== "google"}
                  onClick={() => {
                    receiptsForm.setFieldValue("searchType", "google");
                  }}
                >
                  <Icon
                    icon="GooglePayIcon"
                    style={{ width: "100%", height: "100%" }}
                  />
                </Button>
              )}
              {isSearchMethodEnabled.externalId && (
                <Button
                  style={{ width: searchButtonWidth }}
                  outline={receiptsForm.values.searchType !== "externalId"}
                  onClick={() => {
                    receiptsForm.setFieldValue("searchType", "externalId");
                  }}
                >
                  <BankStatementIcon
                    color={
                      receiptsForm.values.searchType === "externalId"
                        ? "white"
                        : flavor.getMainColor()
                    }
                  />
                </Button>
              )}
            </ButtonGroup>
          </Col>
        </FormGroup>
        {receiptsForm.values.searchType && (
          <>
            {receiptsForm.values.searchType === "apple" && (
              <ApplePayInstruction />
            )}
            {receiptsForm.values.searchType === "google" && (
              <GooglePayInstructions />
            )}
            {receiptsForm.values.searchType === "externalId" && (
              <Row className="justify-content-center">
                <Col sm={7} md={7}>
                  <p className="fw-bolder">
                    {translate("receiptsHome.instructionExternalId")}
                  </p>
                </Col>
              </Row>
            )}
            {(receiptsForm.values.searchType === "card" ||
              receiptsForm.values.searchType === "apple" ||
              receiptsForm.values.searchType === "google") && (
              <FormGroup row className="justify-content-center">
                <RequiredLabel
                  for="input-cc-last-digits"
                  sm={3}
                  className="fw-bolder"
                >
                  {getLast4Message(receiptsForm.values.searchType)}
                </RequiredLabel>
                <Col sm={4}>
                  <Input
                    id="input-cc-last-digits"
                    pattern="\d\d\d\d"
                    name="customerPaymentInstrumentLast4"
                    placeholder="e.g. 1234"
                    value={receiptsForm.values.customerPaymentInstrumentLast4}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      if (value.length < 5) {
                        receiptsForm.handleChange(e);
                      }
                    }}
                    onBlur={receiptsForm.handleBlur}
                    maxLength={4}
                  />
                  {receiptsForm.errors.customerPaymentInstrumentLast4 &&
                  receiptsForm.touched.customerPaymentInstrumentLast4 ? (
                    <ErrorMessage>
                      {receiptsForm.errors.customerPaymentInstrumentLast4}
                    </ErrorMessage>
                  ) : null}
                </Col>
              </FormGroup>
            )}
            {receiptsForm.values.searchType === "externalId" && (
              <FormGroup row className="justify-content-center">
                <RequiredLabel
                  for="input-cc-last-digits"
                  sm={3}
                  className="fw-bolder"
                >
                  {translate("receiptsHome.externalIdLabel")}
                </RequiredLabel>
                <Col sm={4}>
                  <Input
                    id="input-external-id"
                    name="externalId"
                    placeholder={translate(
                      "receiptsHome.externalIdPlaceholder"
                    )}
                    value={receiptsForm.values.externalId}
                    onChange={receiptsForm.handleChange}
                    onBlur={receiptsForm.handleBlur}
                  />
                  {receiptsForm.errors.externalId &&
                  receiptsForm.touched.externalId ? (
                    <ErrorMessage>
                      {receiptsForm.errors.externalId}
                    </ErrorMessage>
                  ) : null}
                </Col>
              </FormGroup>
            )}
            {receiptsForm.values.searchType === "card" &&
              appConfig.searchByCreditCardRequiresExpiration && (
                <FormGroup row className="justify-content-center">
                  <RequiredLabel
                    for="input-exp-date"
                    sm={3}
                    className="fw-bolder"
                  >
                    {translate("receiptsHome.expDate")}
                  </RequiredLabel>
                  <Col sm={4}>
                    <Input
                      id="input-exp-date"
                      name="customerPaymentInstrumentExpDate"
                      placeholder="MM/YY"
                      pattern="\d\d/\d\d"
                      value={
                        receiptsForm.values.customerPaymentInstrumentExpDate
                      }
                      onChange={receiptsForm.handleChange}
                      onBlur={receiptsForm.handleBlur}
                      mask="99/99"
                      tag={InputMask}
                    />
                    {receiptsForm.errors.customerPaymentInstrumentExpDate &&
                    receiptsForm.touched.customerPaymentInstrumentExpDate ? (
                      <ErrorMessage>
                        {receiptsForm.errors.customerPaymentInstrumentExpDate}
                      </ErrorMessage>
                    ) : null}
                  </Col>
                </FormGroup>
              )}
            <FormGroup row className="justify-content-center">
              <RequiredLabel
                for="input-visit-date"
                sm={3}
                className="fw-bolder"
              >
                {translate("receiptsHome.visitDate")}
              </RequiredLabel>
              <Col sm={4}>
                <Input
                  id="input-visit-date"
                  name="visitDate"
                  type="date"
                  placeholder="DD/MM/YYYY"
                  value={receiptsForm.values.visitDate}
                  onChange={receiptsForm.handleChange}
                  onBlur={receiptsForm.handleBlur}
                />
                {receiptsForm.errors.visitDate &&
                receiptsForm.touched.visitDate ? (
                  <ErrorMessage>{receiptsForm.errors.visitDate}</ErrorMessage>
                ) : null}
              </Col>
            </FormGroup>
            <FormGroup row className="justify-content-center">
              <Label for="input-show-email" sm={3} className="fw-bolder">
                {translate("receiptsHome.registerEmail")}
              </Label>
              <Col sm={4}>
                <Input
                  id="input-email"
                  name="email"
                  type="email"
                  value={receiptsForm.values.email}
                  onChange={receiptsForm.handleChange}
                  onBlur={receiptsForm.handleBlur}
                  placeholder="Optional"
                />
                {receiptsForm.errors.email && receiptsForm.touched.email ? (
                  <ErrorMessage>{receiptsForm.errors.email}</ErrorMessage>
                ) : emailStatus === "success" ? (
                  <SuccessMessage>
                    {translate("receiptsHome.registerEmailSuccess")}
                  </SuccessMessage>
                ) : emailStatus === "error" ? (
                  <WarningMessage>
                    {translate("receiptsHome.registerEmailFail")}
                  </WarningMessage>
                ) : null}
              </Col>
            </FormGroup>
            <Row className="justify-content-center">
              <Col sm={7} md={7}>
                {children}
              </Col>
            </Row>
            {receiptsForm.values.captchaToken ? (
              <Row className="justify-content-center">
                <Col sm={3} md={7} style={{ padding: "20px 0" }}>
                  <Row>
                    <Button
                      type="submit"
                      disabled={!receiptsForm.isValid}
                      title={translate("receiptsHome.submit")}
                    >
                      {translate("receiptsHome.submit")}
                    </Button>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Row className="justify-content-center w-auto">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={config.RECAPTCHA_KEY}
                  onChange={(token: string | null) => {
                    receiptsForm.setFieldValue("captchaToken", token);
                  }}
                />
              </Row>
            )}
          </>
        )}
      </Form>
    </div>
  );
};
