import { ITranslation } from './types'

export const fr: ITranslation = {
  messageFallbacks: {
    homeOrderNotReadyMsg: 'Just left the store? Your receipt is being processed, please check back in a while.<br/>In case of any issues, reach out for support at <a style="white-space: nowrap;" href="mailto:support@aifi.io">support@aifi.io</a>'
  },
  api: {
    E_FORCED_LOGOUT: `Vous avez été déconnecté car l'accès n'est pas autorisé.`,
  },
  orderStatus: {
    draft: 'Draft',
    contested: 'Contested',
    paid: 'Paid',
    unpaid: 'Unpaid',
    pending: 'Pending',
    reviewed: 'Reviewed'
  },
  receiptsList: {
    headings: {
      date: "Date",
      time: "Time",
      status: "Status",
      total: "Total",
    },
    action: {
      view: "View",
    },
    statusTooltip: {
      draft: "Draft - Order being processed by the system",
      paid: "Paid - Order has been processed and paid",
      paymentProcessing:
        "Payment Processing - Order has been processed and payment is under process",
      unpaid: "Unpaid - Order has been processed but payment failed",
      pending:
        "Pending - Order has been contested by shopper and is pending for review by the retailer",
      reviewed:
        "Reviewed - Order has been contested by shopper and the retailer has submitted the revision",
    },
  },
  receiptDetailScreen: {
    headerTitle: 'Details du reçu',
    subtotal: 'Sous-total',
    taxBreakdown: {
      headers: {
        rate: "Tax %",
        netto: "Netto",
        brutto: "Brutto",
        tax: "Tax",
        total: "Total:"
      }
    },
    tax: 'Tax',
    taxCRV: 'CRV',
    total: 'Total',
    processTime: 'Temps de traitement',
    orderId: 'Ordre ID',
    date: 'Date',
    paymentStatus: 'Statut de paiement',
    listOfProducts: 'Produits'
  },
  receiptDetailComplain: {
    reportProblem: 'Signaler un problème',
    submitReview: 'Envoyer pour avis',
    confirmTitle: 'Reçu envoyé',
    confirmButton: 'Fait',
    addMissingItem: 'Ajouter un élément manquant',
    addMissingItemMessage: 'Ajouter un message (facultatif)',
  },
  receiptsScreen: {
    minutes: 'minutes',
    hours: 'les heures',
    days: 'journées',
    label: {
      contested: 'EN REVUE',
      draft: 'BROUILLON',
      empty: 'VIDER',
      paid: 'PAYÉ',
      pending: 'BROUILLON',
      refunded: 'REMBOURSÉ',
      reviewed: 'MODIFIÉ',
      unknown: 'Inconnu',
      unpaid: 'NON PAYÉ',
    },
    message: {
      contested: 'Message contesté',
      draft: 'Brouillon de message',
      empty: 'Message vide',
      paid: 'Message payant',
      pending: 'Message en attente',
      refunded: 'Message remboursé',
      reviewed: 'Message examiné',
      unpaid: 'Message non payé',
    },
  },
  slideModal: {
    close: 'Fermer',
    searchPlaceholder: 'Rechercher un élément',
  },
  receiptsHome: {
    receiptPortal: "Portail des reçus",
    selectPaymentType: "Sélectionnez la méthode préférée pour trouver votre reçu",
    last4: "Last four digits of your payment method",
    last4Apple: "Last four digits of your Apple Pay Number",
    last4Google: "Last four digits of your Google Pay Number",
    instructionApple: "Locate last four digits of your Apple Pay Card Number in your Apple Wallet",
    instructionGoogle: "Locate last four digits of your Google Pay Card Number in your Wallet",
    instructionExternalId:
      'Trouvez le numéro de référence de paiement dans votre application de paiement ou votre relevé bancaire.',
    externalIdLabel: 'Numéro de référence de paiement',
    externalIdPlaceholder: '',
    expDate: "Expiration date",
    visitDate: "Date of your visit",
    submit: "Retrieve Receipt(s)",
    registerEmail:
      "Enter your email to subscribe to future receipts",
    registerEmailSuccess: "Email address updated successfully.",
    registerEmailFail: "Email address wasn't updated because there wasn't any receipts associated with provided credit card information and/or visit date.",
  },
}
