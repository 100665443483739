import * as R from 'ramda';

import { translate } from "../../../lib/i18n";
import { color2 } from "../../../styles";
import { Tag } from "../../../components/Tag";
import { formatCurrency, formatTaxName } from "src/lib/utils";
import { TaxBreakdownTableData } from "./types";
import { hexToRgba } from '../../../utils';

const createHeaderTagInfo = (
  title: string,
  tagInfo: string,
  tagColor: string,
  tagBackgroundColor: string
) => ({
  title,
  tagInfo,
  tagColor,
  tagBackgroundColor,
});

const getHeaderTagInfo = (order: ApiOrderDetail) => {
  switch (order.status) {
    case "draft":
      return createHeaderTagInfo(
        translate("receiptsScreen.label.pending") as string,
        translate("receiptsScreen.message.pending") as string,
        color2.GRAY4,
        hexToRgba(color2.GRAY4, 0.15)
      );

    case "contested":
      return createHeaderTagInfo(
        translate("receiptsScreen.label.contested") as string,
        translate("receiptsScreen.message.contested") as string,
        color2.BLUE_AIFI,
        hexToRgba(color2.BLUE_AIFI, 0.15)
      );

    case "empty":
      return createHeaderTagInfo(
        translate("receiptsScreen.label.empty") as string,
        translate("receiptsScreen.message.empty") as string,
        color2.GRAY4,
        hexToRgba(color2.GRAY4, 0.15)
      );

    case "unpaid":
      return createHeaderTagInfo(
        translate("receiptsScreen.label.unpaid") as string,
        translate("receiptsScreen.message.unpaid") as string,
        color2.RED,
        hexToRgba(color2.RED, 0.15)
      );

    case "reviewed":
      return createHeaderTagInfo(
        translate("receiptsScreen.label.reviewed") as string,
        translate("receiptsScreen.message.reviewed") as string,
        color2.GREEN,
        hexToRgba(color2.GREEN, 0.15)
      );

    default:
      return null;
  }
};

export const buildHeaderTag = (order: ApiOrderDetail) => {
  const headerTagInformation = getHeaderTagInfo(order);
  if (headerTagInformation) {
    return (
      <Tag
        title={headerTagInformation.title}
        tagInfo={headerTagInformation && headerTagInformation.tagInfo}
        tagColor={headerTagInformation.tagColor}
        tagBackgroundColor={headerTagInformation.tagBackgroundColor}
      />
    );
  }
  return null;
};

export const buildHeaderValue = (order: ApiOrderDetail): null | string => {
  switch (order.status) {
    case "paid":
      return translate("receiptsScreen.label.paid") as string;
    case "empty":
      return translate("receiptsScreen.label.empty") as string;
    default:
      return null;
  }
};

export const buildTaxBreakdownTable = (order: ApiOrderDetail, taxBreakdownItems: TaxBreakdown[]): TaxBreakdownTableData => {
  const longestRateLength = R.reduce<number, number>(
    R.max,
    0,
    taxBreakdownItems?.map(({ rate }) => rate?.length ?? 0) ?? []
  );
  const headers = [
    {
      value: translate("receiptDetailScreen.taxBreakdown.headers.rate") as string,
    },
    ...(taxBreakdownItems
      .map(({ name, rate }) => ({
        value: formatTaxName(name, rate ?? "", longestRateLength),
      }))
      .values() ?? []),
    {
      value: translate("receiptDetailScreen.taxBreakdown.headers.total") as string,
    },
  ];
  const taxes = [
    {
      value: translate("receiptDetailScreen.taxBreakdown.headers.tax") as string,
    },
    ...(taxBreakdownItems
      .map(({ taxTotal }) => ({
        value: formatCurrency(taxTotal, order.currencyCode),
      }))
      .values() ?? []),
    {
      value: formatCurrency(order.totalTax, order.currencyCode),
    },
  ];

  const netto = [
    {
      value: translate("receiptDetailScreen.taxBreakdown.headers.netto") as string,
    },
    ...(taxBreakdownItems
      .map(({ subtotal }) => ({
        value: formatCurrency(subtotal, order.currencyCode),
      }))
      .values() ?? []),
    {
      value: formatCurrency(order.subtotalPrice ?? '', order.currencyCode),
    },
  ];

  const brutto = [
    {
      value: translate("receiptDetailScreen.taxBreakdown.headers.brutto") as string,
    },
    ...(taxBreakdownItems
      .map(({ total }) => ({
        value: formatCurrency(total, order.currencyCode),
      }))
      .values() ?? []),
    {
      value: formatCurrency(order.totalPrice, order.currencyCode),
    },
  ];

  return {
    headers, netto, taxes, brutto
  }
};
