import * as Yup from "yup";
import envVars from "./env";

export { envVars };

export const flavorName = envVars.REACT_APP_NAME;

const environmentFlavorConfigSchema = Yup.object({
  logo: Yup.object({
    uri: Yup.string().optional(),
  })
    .nullable()
    .optional(),
  colors: Yup.object({
    main: Yup.string().optional(),
    icon: Yup.string().optional(),
    spinner: Yup.string().optional(),
    dashLine: Yup.string().optional(),
  })
    .nullable()
    .optional(),
  formats: Yup.object({
    defaultDateFormat: Yup.string().optional(),
    defaultTimeFormat: Yup.string().optional(),
    tableDateFormat: Yup.string().optional(),
    tableTimeFormat: Yup.string().optional(),
  })
    .nullable()
    .optional(),
  flags: Yup.object({
    hidePoweredByAiFi: Yup.bool().optional(),
    orderDetailsShowHeader: Yup.bool().optional(),
    orderDetailsShowDetailsAtTheTop: Yup.bool().optional(),
    orderDetailsShowProductsListTitle: Yup.bool().optional(),
    orderDetailsShowFooterOrderId: Yup.bool().optional(),
    contestingEnabled: Yup.bool().optional(),
    downloadReceiptEnabled: Yup.bool().optional(),
  })
    .nullable()
    .optional(),
});

type EnvironmentFlavorConfig = Yup.InferType<
  typeof environmentFlavorConfigSchema
>;

export const config = {
  OASIS_API_URL: process.env.REACT_APP_HOST_URL || window.location.origin,
  RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_CLIENT_KEY || "",
  messages: {
    contestConfirmation:
      process.env.REACT_APP_MSG_CONTEST_CONFIRMATION ||
      "Your receipt has been submitted with success and we will get back to you shortly. We will not charge anything on the top of the original receipt amount. Thank you for helping us to improve!",
    poster: {
      receiptURL:
        process.env.REACT_APP_MSG_POSTER_RECEIPT_URL || window.location.origin,
      content:
        process.env.REACT_APP_MSG_POSTER_CONTENT ||
        `To check your receipt:\nScan the QR code\n\nor\n\nVisit receipt portal\n#{receiptURL}\n\nYour receipt will be available after a while.\nThank you for shopping with us!`,
    },
  },
  environmentFlavorConfig: readEnvironmentFlavorConfig(),
};

function readEnvironmentFlavorConfig(): EnvironmentFlavorConfig | null {
  if (flavorName !== "Environment") {
    return null;
  }

  const configString = process.env.REACT_APP_ENVIRONMENT_FLAVOR;
  if (!configString) {
    return null;
  }

  try {
    const validated = environmentFlavorConfigSchema.validateSync(
      JSON.parse(configString)
    );
    return validated;
  } catch (error) {
    console.error(
      "Could not read the flavour configuration. Falling back to default.",
      error
    );
    return null;
  }
}
